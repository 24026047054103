import React from 'react';
import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        textTransform: props => props.texttransform ? props.texttransform : 'initial',
        textDecoration: props => props.textDecoration ? props.textDecoration : 'initial',
        borderRadius: props => props.borderradius ? props.borderradius : 'initial',
        padding: props => props.padding ? props.padding : null,
        '&:hover': {
            color: props => props.changetextcolor ? props.changetextcolor : 'unset'
        },
        margin: props => props.margin ? props.margin : null,
    },
    circularProgressStyle: {
        position: 'absolute'
    }
});

export const primaryButton = {
    color: 'primary',
    variant: 'contained',
    borderradius: '5px',
    size: 'small',
    changetextcolor: '#fff'
}

export const ButtonTemplate = (props) => {
    const classes = useStyles(props);
    return (
        <Button
            {...props}
            className={classes.root}
            ref={props.anchorref}
        >
            <Typography variant='button'>
                {props.disabled && !props.notloading &&
                    <Grid container justify='center'>
                        <CircularProgress color='primary' size={25} thickness={5} className={classes.circularProgressStyle} />
                    </Grid>
                }
                {props.children}
            </Typography>
        </Button>
    )
}